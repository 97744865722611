import { phaseCodeService } from "@dwo/shared/dist/services/phaseCodeService";
import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { Error, ExpandMore } from "@material-ui/icons";
import { TooltipCustom } from "components/TooltipCustom";
import {
  removeValueByIdAndType,
  selectValues,
  setValues,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PhaseCodeModel } from "@dwo/shared/src/models/phaseCodeModel";
import { FormikValues, useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface CrewOverviewPhaseCodeSelectProps {
  id: string;
  idRow: number;
  phaseCode: string;
  isEditModeActive: boolean;
  jobId: number;
  setAll?: boolean;
  allIdRows?: string[];
}

interface StyleProps {
  backgroundColor?: string;
  borderRadius?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      backgroundColor: ({ backgroundColor }: StyleProps) =>
        backgroundColor || theme.palette.grey[300],
      borderRadius: ({ borderRadius }: StyleProps) => borderRadius || "2px",
      height: "40px",
      width: "105px",
      padding: 0,
      "& .MuiSelect-select:focus": {
        backgroundColor: theme.palette.grey[300],
      },
      "& .MuiSelect-icon": {
        right: 0,
        top: "calc(50% - 16px)",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.dark,
        fontSize: "2rem",
      },
      "& .MuiFilledInput-input": {
        padding: "10px 4px",
      },
    },
    dropdownMenu: {
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: "#838385",
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    root: {
      color: theme.palette.grey[600],
    },
    errorIcon: {
      position: "absolute",
      right: "-28px",
      bottom: "6px",
    },
  }),
);

const filterPhaseCodes = (phaseCode: string): boolean => {
  const plainPhaseCode = phaseCode.replace(/[^a-zA-Z0-9]/g, "");

  const ignorePhaseCodeMap: Record<string, boolean> = {
    ASPH: true,
    FUEL: true,
    FUSE: true,
    GRVL: true,
    HAUL: true,
    INEQ: true,
    INSU: true,
    MAOH: true,
    MATE: true,
    MATG: true,
    MATL: true,
    OVHD: true,
    PERD: true,
    SAND: true,
    SHOR: true,
    TANE: true,
    TOOLB: true,
    TOOLE: true,
    TOOLG: true,
    "999999999999": true,
    OVCOGS: true,
    Rebill: true,
  };

  return !ignorePhaseCodeMap[plainPhaseCode];
};

export function CrewOverviewPhaseCodeSelect({
  id,
  idRow,
  phaseCode,
  isEditModeActive,
  jobId,
  setAll,
  allIdRows,
}: CrewOverviewPhaseCodeSelectProps) {
  const dispatch = useDispatch();
  const values = useSelector(selectValues);
  const [phaseCodeList, setPhaseCodeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyPhaseCodeList, setIsEmptyPhaseCodeList] = useState(false);
  const [phaseCodeValue, setPhaseCodeValue] = useState("");
  const [phaseCodeValueError, setPhaseCodeValueError] = useState<
    boolean | undefined
  >(undefined);
  const [isRowActive, setIsRowActive] = useState(false);

  const classes = useStyles({
    backgroundColor: "#F0EFEF",
    borderRadius: "0px",
  });

  const formik = useFormik({
    initialValues: {
      phaseCode,
    },
    initialErrors: {
      phaseCode,
    },
    validate: (values) => {
      const errors: Partial<FormikValues> = {};
      return errors;
    },
    onSubmit: noop,
  });
  const formikRef = useRef(formik);
  const valuesRef = useRef(values);
  const current = useMemo(() => {
    return values.find((value) => value.idRow === Number(idRow));
  }, [values]);

  useEffect(() => {
    if (current?.inputValue) {
      formikRef.current.setFieldValue("phaseCode", current.inputValue);
    }
  }, [current]);

  const getPhaseCodes = async () => {
    try {
      setIsLoading(true);
      const phaseCodesReponse = await phaseCodeService.getPhaseCodes(jobId);

      if (phaseCodesReponse?.data && phaseCodesReponse.data.length !== 0) {
        setIsEmptyPhaseCodeList(false);
        setPhaseCodeList((phaseCodesReponse?.data as []) ?? []);
      } else {
        setIsEmptyPhaseCodeList(true);
      }
      setIsLoading(false);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (phaseCode === "" && isRowActive) {
      setPhaseCodeValueError(true);
    } else {
      setPhaseCodeValueError(false);
    }
  }, [isRowActive]);

  useEffect(() => {
    const current = values.find((value) => value.idRow === Number(idRow));
    setIsRowActive(Boolean(current));
  }, [values, idRow]);

  useEffect(() => {
    if (formik.isValid || isRowActive) {
      if (setAll) {
        (allIdRows || [])?.forEach((idRow) => {
          dispatch(
            setValues({
              id,
              idRow: Number(idRow),
              inputValue: formik.values.phaseCode,
              inputError: phaseCodeValueError,
            }),
          );
        });
      } else {
        dispatch(
          setValues({
            id,
            idRow: Number(idRow),
            inputValue: formik.values.phaseCode,
            inputError: phaseCodeValueError,
          }),
        );
      }
    }
  }, [
    dispatch,
    formik.values.phaseCode,
    phaseCodeValueError,
    id,
    idRow,
    formik.isValid,
    isRowActive,
  ]);

  useEffect(() => {
    if (
      phaseCodeValueError ||
      (formik.touched.phaseCode && Boolean(formik.errors.phaseCode))
    ) {
      dispatch(removeValueByIdAndType({ id: Number(idRow), type: id }));
    }
  }, [
    dispatch,
    idRow,
    id,
    formik.touched.phaseCode,
    formik.errors.phaseCode,
    phaseCodeValueError,
  ]);

  useEffect(() => {
    if (!isEditModeActive) {
      setPhaseCodeValue("");
      setPhaseCodeValueError(undefined);
    }
  }, [isEditModeActive]);

  useEffect(() => {
    getPhaseCodes();
  }, []);

  return (
    <Box flex={1} maxWidth="85px">
      {phaseCodeValueError && (
        <Box position="relative" top="40px" right="-18px">
          <TooltipCustom
            title={
              <Typography className={classes.errorMessage} variant="subtitle2">
                Select a Phase Code
              </Typography>
            }
            placement="bottom"
          >
            <Error className={classes.errorIcon} color="error" />
          </TooltipCustom>
        </Box>
      )}
      <FormControl fullWidth required variant="filled">
        <Select
          id="phaseCode"
          classes={{ root: classes.root }}
          className={classes.dropdown}
          IconComponent={ExpandMore}
          labelId="phaseCode"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: undefined,
            PopoverClasses: {
              paper: classes.dropdownMenu,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          {...formik.getFieldProps("phaseCode")}
        >
          <MenuItem value="- - -">- - -</MenuItem>
          {!isEmptyPhaseCodeList && isLoading ? (
            <LoadingSpinner />
          ) : (
            phaseCodeList
              ?.filter((item: PhaseCodeModel) =>
                filterPhaseCodes(item.phaseCode),
              )
              .map((phaseCodeItem: PhaseCodeModel) => (
                <MenuItem value={phaseCodeItem.phaseCode}>
                  {phaseCodeItem.phaseCode}
                </MenuItem>
              ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
