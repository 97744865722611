import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { cloneDeep } from "lodash";
import { CreateDWOModal } from "components/jobDetails/CreateDWO/CreateDWOModal";
import { CreateEditJobModal } from "components/jobForm/CreateEditJobModal";
import {
  DEFAULT_PROGRESS,
  JobProgressCard,
} from "components/jobDetails/jobProgress/JobProgressCard";
import { ExpensesHistoryModal } from "components/jobDetails/expensesModal/ExpensesHistoryModal";
import { HazardHistoryModal } from "components/jobDetails/hazardHistoryModal/HazardHistoryModal";
import { JobDetailsCard } from "components/jobDetails/JobDetailsCard";
import { JobDetailsTabs } from "components/jobDetails/JobDetailsTabs";
import { JobNotesCard } from "components/jobDetails/JobNotesCard";
import { JobProgressModal } from "components/jobDetails/jobProgress/JobProgressModal";
import { Layout } from "components/layout/Layout";
import { TimesheetsModal } from "components/jobDetails/jobProgress/TimesheetsModal";
import { VehicleHistoryModal } from "components/jobDetails/vehicleHistoryModal/VehicleHistoryModal";
import { VehicleModal } from "components/jobDetails/vehicleModal/VehicleModal";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { getAllRegions } from "features/region/regionSlice";
import {
  getJobById,
  selectCurrentJob,
  selectIsLoading,
  selectIsLoadingUpdate,
  updateJob,
} from "features/jobs/jobsSlice";
import { setCurrentJob } from "features/dwo/dwoSlice";
import { setForemanId } from "features/vehicles/vehiclesSlice";
import { ROOT_JOB_MANAGEMENT } from "routes/Roots";
import { DEFAULT_EMPTY, JobStatusValues } from "utils/jobUtils";
import { initialJobDetailsQuery } from "utils/jobDetailsUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: "24px",
      padding: 0,
    },
    backIcon: {
      color: theme.palette.primary.main,
    },
    button: {
      maxWidth: "194px",
      padding: "8px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    buttonsContainer: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "flex-end",
      margin: "0 24px",
      "& button:nth-child(1)": {
        marginRight: "24px",
      },
      "& button:nth-child(2)": {
        marginRight: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        margin: "0 24px 24px 24px",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& button:nth-child(1)": {
          marginRight: 0,
        },
        "& button:nth-child(2)": {
          marginRight: 0,
          marginTop: "24px",
        },
        "& button:nth-child(3)": {
          marginTop: "24px",
        },
      },
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    rightCardContainer: {
      margin: "0 24px 24px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "0 24px 24px 24px",
      },
    },
  }),
);

export function JobDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const currentJob = useSelector(selectCurrentJob);
  const isLoadingJobData = useSelector(selectIsLoading);
  const isLoadingUpdate = useSelector(selectIsLoadingUpdate);
  const [isEditJobModalOpen, setIsEditJobModalOpen] = useState<boolean>(false);
  const [isExpensesModaOpen, setIsExpensesModalOpen] = useState<boolean>(false);
  const [
    isHazardHistoryModalOpen,
    setIsHazardHistoryModalOpen,
  ] = useState<boolean>(false);
  const [isJobProgressModalOpen, setIsJobProgressModalOpen] = useState<boolean>(
    false,
  );
  const [
    isTimesheetsModalOpen,
    setIsTimesheetsJobModalOpen,
  ] = useState<boolean>(false);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState<boolean>(false);
  const [
    isVehicleHistoryModalOpen,
    setIsVehicleHistoryModalOpen,
  ] = useState<boolean>(false);

  const [isCreateDWOModalOpen, setIsCreateDWOModalOpen] = useState<boolean>(
    false,
  );

  const classes = useStyles();

  const isLoading = isLoadingJobData || isLoadingUpdate;

  const estimatedProgress =
    currentJob && currentJob.progressPercentage
      ? parseFloat(currentJob.progressPercentage)
      : DEFAULT_PROGRESS;

  const status =
    (currentJob?.status as JobStatusValues) || JobStatusValues.UNASSIGNED;

  useEffect(() => {
    dispatch(getJobById(jobId, initialJobDetailsQuery));
    dispatch(getAllRegions());
  }, [jobId, dispatch]);

  const handleClickBack = () => {
    history.push(ROOT_JOB_MANAGEMENT);
  };

  const handleClickCancelEditJob = () => setIsEditJobModalOpen(false);

  const handleClickCancelJobProgressModal = () =>
    setIsJobProgressModalOpen(false);

  const handleClickCarButton = (foremanId: number) => {
    dispatch(setForemanId(foremanId));
    setIsVehicleModalOpen(true);
  };

  const handleClickCloseExpensesModal = () => setIsExpensesModalOpen(false);

  const handleClickCloseTimesheetsModal = () => {
    dispatch(setCurrentJob(undefined));
    setIsTimesheetsJobModalOpen(false);
  };

  const handleClickCloseVehicleModal = () => setIsVehicleModalOpen(false);

  const handleClickCloseVehicleHistoryModal = () =>
    setIsVehicleHistoryModalOpen(false);

  const handleClickCloseHazardHistoryModal = () =>
    setIsHazardHistoryModalOpen(false);

  const handleClickCloseCreateDWOModal = () => setIsCreateDWOModalOpen(false);

  const handleClickEditJob = () => setIsEditJobModalOpen(true);

  const handleClickEditJobProgress = () => setIsJobProgressModalOpen(true);

  const handleClickExpensesButton = () => setIsExpensesModalOpen(true);

  const handleClickVehicleButton = () => setIsVehicleHistoryModalOpen(true);

  const handleClickViewTimesheet = () => setIsTimesheetsJobModalOpen(true);

  const handleClickHazardButton = () => setIsHazardHistoryModalOpen(true);

  const handleClickSaveJob = (editedJob: JobModel) => {
    dispatch(updateJob(editedJob, initialJobDetailsQuery));
    setIsEditJobModalOpen(false);
  };

  const handleClickSaveJobProgressModal = (
    status: JobStatusValues,
    progress: string,
  ) => {
    const updatedJob = cloneDeep(currentJob as JobModel);
    updatedJob.progressPercentage = progress;
    updatedJob.status = status;
    dispatch(updateJob(updatedJob, initialJobDetailsQuery));
    setIsJobProgressModalOpen(false);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Box alignItems="center" display="flex" margin="32px 24px 24px 24px">
            <IconButton
              aria-label="Go Back"
              className={classes.backButton}
              onClick={handleClickBack}
            >
              <ArrowBack className={classes.backIcon} fontSize="large" />
            </IconButton>
            <Typography className={classes.header} component="h1">
              Job Details
            </Typography>
          </Box>
        </Grid>

        <Grid item md={8} xs={12}>
          <Box className={classes.buttonsContainer}>
            {/* <Button
              className={classes.button}
              disabled={isLoading}
              variant="outlined"
              onClick={handleClickExpensesButton}
            >
              Job Site Photos History
            </Button> */}

            <Button
              className={classes.button}
              disabled={isLoading}
              variant="outlined"
              onClick={handleClickHazardButton}
            >
              Hazard Form History
            </Button>

            <Button
              className={classes.button}
              disabled={isLoading}
              variant="outlined"
              onClick={handleClickVehicleButton}
            >
              Vehicle History
            </Button>
          </Box>
        </Grid>

        <Grid item md={9} xs={12}>
          <JobDetailsCard
            isLoading={isLoading}
            jobData={currentJob}
            onClickEdit={handleClickEditJob}
          />
          <JobDetailsTabs onClickCarButton={handleClickCarButton} />
        </Grid>

        <Grid item md={3} xs={12}>
          <Box className={classes.rightCardContainer}>
            <JobProgressCard
              onClickEditJobProgress={handleClickEditJobProgress}
              onClickViewTimesheet={handleClickViewTimesheet}
            />
          </Box>

          <Box className={classes.rightCardContainer}>
            <JobNotesCard />
          </Box>
        </Grid>
      </Grid>

      {isEditJobModalOpen && (
        <CreateEditJobModal
          isEditing
          jobData={currentJob}
          isOpen={isEditJobModalOpen}
          onClickClose={handleClickCancelEditJob}
          onSubmit={handleClickSaveJob}
        />
      )}

      {isExpensesModaOpen && (
        <ExpensesHistoryModal
          jobId={currentJob?.jobId || DEFAULT_EMPTY}
          isOpen={isExpensesModaOpen}
          onClickClose={handleClickCloseExpensesModal}
        />
      )}

      {isHazardHistoryModalOpen && (
        <HazardHistoryModal
          jobId={currentJob?.jobId || DEFAULT_EMPTY}
          isOpen={isHazardHistoryModalOpen}
          onClickClose={handleClickCloseHazardHistoryModal}
        />
      )}

      {isJobProgressModalOpen && (
        <JobProgressModal
          isOpen={isJobProgressModalOpen}
          progress={estimatedProgress}
          status={status}
          onClickCancel={handleClickCancelJobProgressModal}
          onSubmit={handleClickSaveJobProgressModal}
        />
      )}

      {isTimesheetsModalOpen && (
        <TimesheetsModal
          isOpen={isTimesheetsModalOpen}
          onClickClose={handleClickCloseTimesheetsModal}
        />
      )}

      {isVehicleModalOpen && (
        <VehicleModal
          jobDisplayedId={currentJob?.jobId || DEFAULT_EMPTY}
          isOpen={isVehicleModalOpen}
          onClickClose={handleClickCloseVehicleModal}
        />
      )}

      {isVehicleHistoryModalOpen && (
        <VehicleHistoryModal
          isOpen={isVehicleHistoryModalOpen}
          jobDisplayedId={currentJob?.jobId || DEFAULT_EMPTY}
          onClickClose={handleClickCloseVehicleHistoryModal}
        />
      )}

      {/* {isCreateDWOModalOpen && (
        <CreateDWOModal
          isOpen={isCreateDWOModalOpen}
          onClickClose={handleClickCloseCreateDWOModal}
        />
      )} */}
    </Layout>
  );
}
