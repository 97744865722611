import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import { EditIconButton } from "components/icons/EditIconButton";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { JobStatusDot } from "components/JobStatusDot";
import { LoadingSpinner } from "components/LoadingSpinner";
import { NoTimesheetsModal } from "components/jobDetails/CreateDWO/NoTimesheetsModal";
import { AllProgressChart } from "./AllProgressChart";
import { EstimatedProgressChart } from "./EstimatedProgressChart";
import { LaborProgressChart } from "./LaborProgressChart";
import { ProgressDescriptionItem } from "./ProgressDescriptionItem";
import { TimeProgressChart } from "./TimeProgressChart";
import {
  createEmptyTimesheet,
  setCurrentJob,
  setInitialJobId,
} from "features/dwo/dwoSlice";
import {
  getJobDWOs,
  selectCurrentJob,
  selectIsLoading,
  selectIsLoadingJobDWOs,
  selectIsLoadingUpdate,
  selectJobDWOs,
} from "features/jobs/jobsSlice";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { employeeCrewService } from "@dwo/shared/dist/services/employeeCrewService";
import { enDashFormatDate } from "utils/dateUtils";
import { getJobStatusLabel, JobStatusValues } from "utils/jobUtils";
import { hasOnlyOneCrew, PROGRESS_TYPE_VALUES } from "utils/jobDetailsUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "8px 20px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    chartContainer: {
      marginBottom: "60px",
    },
    descriptionContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: "56px",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
    },
    statusLabel: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginRight: "16px",
    },
    statusValue: {
      color: theme.palette.grey[500],
      marginRight: "8px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    viewMore: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
      padding: "24px",
    },
    goBackModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    goBackButton: {
      padding: "8px 4px",
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      maxWidth: "615px",
      width: "90%",
    },
  }),
);

interface JobProgressCardProps {
  onClickEditJobProgress: () => void;
  onClickViewTimesheet: () => void;
}

export const DEFAULT_PROGRESS = 0.0;

export function JobProgressCard({
  onClickEditJobProgress,
  onClickViewTimesheet,
}: JobProgressCardProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const isLoadingJobData = useSelector(selectIsLoading);
  const isLoadingJobDWOs = useSelector(selectIsLoadingJobDWOs);
  const isLoadingUpdate = useSelector(selectIsLoadingUpdate);
  const jobData = useSelector(selectCurrentJob);
  const jobDWOs = useSelector(selectJobDWOs);
  const [selectedChart, setSelectedChart] = useState<PROGRESS_TYPE_VALUES>(
    PROGRESS_TYPE_VALUES.ALL,
  );
  const [isCreateDWOModalOpen, setIsCreateDWOModalOpen] = useState<boolean>(
    false,
  );
  const [isNoCrewMessage, setIsNoCrewMessage] = useState<boolean>(false);
  const [isNoForemanMessage, setIsNoForemanMessage] = useState<boolean>(false);
  const [isSelectCrewModalOpen, setSelectCrewModalOpen] = useState<boolean>(
    false,
  );

  const handleClickCloseCreateDWOModal = () => setIsCreateDWOModalOpen(false);

  const handleClickCloseModals = () => {
    setIsCreateDWOModalOpen(false);
    setIsNoCrewMessage(false);
    setIsNoForemanMessage(false);
    setSelectCrewModalOpen(false);
  };

  const handleClickCloseTimesheetsModal = () => {
    dispatch(setCurrentJob(undefined));
    setSelectCrewModalOpen(false);
  };

  const classes = useStyles();

  const isLoading = isLoadingJobData || isLoadingJobDWOs || isLoadingUpdate;

  useEffect(() => {
    dispatch(
      getJobDWOs({
        include: [{ crew: ["foreman"] }],
        where: { jobId },
        order: [["createdAt", "DESC"]],
      }),
    );
  }, [jobId, dispatch]);

  const currentHours = jobData?.currentHours || DEFAULT_PROGRESS;

  const totalHours = jobData?.totalHours || DEFAULT_PROGRESS;

  const laborProgress = parseInt(
    ((currentHours * 100) / (totalHours > 0 ? totalHours : 1)).toFixed(0),
    10,
  );

  const estimatedProgress = parseInt(
    (jobData && jobData.progressPercentage
      ? parseFloat(jobData.progressPercentage)
      : DEFAULT_PROGRESS
    ).toFixed(0),
    10,
  );

  const status =
    (jobData?.status as JobStatusValues) || JobStatusValues.UNASSIGNED;

  const totalDays = (() => {
    if (!jobData) {
      return 0;
    }

    const endDate = new Date(jobData.endDate);
    const startDate = new Date(jobData.startDate);

    endDate.setHours(23, 59, 59, 999);
    startDate.setHours(0, 0, 0, 0);

    const dayDifference = differenceInDays(endDate, startDate);

    return dayDifference + 1;
  })();

  const currentDays = (() => {
    if (!jobData) {
      return 0;
    }

    const currentDate = new Date();
    const finishDate = jobData.finalizedDate
      ? new Date(jobData.finalizedDate)
      : new Date();
    const endDate = new Date(jobData.endDate);
    const startDate = new Date(jobData.startDate);

    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startDate.setHours(0, 0, 0, 0);

    if (currentDate <= startDate) {
      return 0;
    }

    if (currentDate <= endDate) {
      return differenceInDays(currentDate, startDate);
    }

    return differenceInDays(finishDate, startDate);
  })();

  const timeProgress = (() => {
    if (!jobData) return 0;

    if (jobData.finalizedDate) {
      const endDate = new Date(jobData.endDate);
      const finishDate = new Date(jobData.finalizedDate);

      endDate.setHours(23, 59, 59, 999);
      finishDate.setHours(23, 59, 59, 999);

      if (finishDate <= endDate) {
        return 100;
      }
    }

    return parseInt(
      ((currentDays * 100) / (totalDays > 0 ? totalDays : 1)).toFixed(0),
      10,
    );
  })();

  const handleClickJobType = (type: PROGRESS_TYPE_VALUES) => {
    if (type === selectedChart) {
      setSelectedChart(PROGRESS_TYPE_VALUES.ALL);
      return;
    }
    setSelectedChart(type);
  };

  const handleClickViewTimesheet = () => {
    if (jobDWOs.length === 0 || hasOnlyOneCrew(jobDWOs)) {
      history.push(`${ROOT_TIMESHEETS_MANAGEMENT}/${jobDWOs[0].dwoId}`);
    }
    if (jobDWOs.length === 0 || !jobData) {
      return;
    }
    dispatch(setInitialJobId(jobData.id));
    onClickViewTimesheet();
  };

  const handleClickOpenCreateDWOModal = async () => {
    const { data: crew } = await crewService.getAll({
      where: {
        jobId: jobData?.id,
      },
    });

    if (crew.length >= 2) {
      setIsCreateDWOModalOpen(false);
      setSelectCrewModalOpen(true);
      return;
    }
    setIsCreateDWOModalOpen(true);
    setSelectCrewModalOpen(false);
  };

  const handleClickCreateDWOModal = async () => {
    const { data: crew } = await crewService.getAll({
      where: {
        jobId: jobData?.id,
      },
      include: ["job"],
    });

    if (!crew[0]) {
      setIsNoCrewMessage(true);
      return;
    }
    const { data: employeeCrew } = await employeeCrewService.getAll({
      where: {
        crewId: crew[0].id,
        isCurrent: true,
        isForeman: true,
      },
    });
    if (!employeeCrew[0]) {
      setIsNoForemanMessage(true);
      return;
    }

    const DWOCreation = await createEmptyTimesheet(
      crew[0],
      enDashFormatDate(new Date(jobData!.startDate)),
      Number(jobData?.id),
      Number(employeeCrew[0].employeeId),
    );
    dispatch(DWOCreation);
    //history.push(`${ROOT_TIMESHEETS_MANAGEMENT}/${createTimesheet}`);
  };

  return (
    <Paper className={classes.paper}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginBottom="16px"
      >
        <Typography className={classes.title} variant="h6">
          Job Progress
        </Typography>
        <EditIconButton
          isDisabled={isLoading}
          onClickEdit={onClickEditJobProgress}
        />
      </Box>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <Box
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            marginBottom="16px"
          >
            <Typography className={classes.statusLabel} variant="body2">
              Status
            </Typography>
            <Box alignItems="center" display="flex">
              <Typography className={classes.statusValue} variant="body2">
                {getJobStatusLabel(status)}
              </Typography>
              <JobStatusDot status={status} />
            </Box>
          </Box>

          <Box className={classes.chartContainer}>
            {selectedChart === PROGRESS_TYPE_VALUES.ALL && (
              <AllProgressChart
                estimatedProgress={estimatedProgress}
                laborProgress={laborProgress}
                timeProgress={timeProgress}
              />
            )}
            {selectedChart === PROGRESS_TYPE_VALUES.ESTIMATED && (
              <EstimatedProgressChart progress={estimatedProgress} />
            )}
            {selectedChart === PROGRESS_TYPE_VALUES.LABOR && (
              <LaborProgressChart
                currentHours={currentHours}
                progress={laborProgress}
                totalHours={totalHours}
              />
            )}
            {selectedChart === PROGRESS_TYPE_VALUES.TIME && (
              <TimeProgressChart
                currentDays={currentDays}
                progress={timeProgress}
                totalDays={totalDays}
              />
            )}
          </Box>

          <Box className={classes.descriptionContainer}>
            <ProgressDescriptionItem
              isSelected={
                selectedChart === PROGRESS_TYPE_VALUES.ALL ||
                selectedChart === PROGRESS_TYPE_VALUES.ESTIMATED
              }
              percentage={estimatedProgress}
              progressType={PROGRESS_TYPE_VALUES.ESTIMATED}
              onClickItem={handleClickJobType}
            />
            <ProgressDescriptionItem
              isSelected={
                selectedChart === PROGRESS_TYPE_VALUES.ALL ||
                selectedChart === PROGRESS_TYPE_VALUES.LABOR
              }
              percentage={laborProgress}
              progressType={PROGRESS_TYPE_VALUES.LABOR}
              onClickItem={handleClickJobType}
            />
            <ProgressDescriptionItem
              isSelected={
                selectedChart === PROGRESS_TYPE_VALUES.ALL ||
                selectedChart === PROGRESS_TYPE_VALUES.TIME
              }
              percentage={timeProgress}
              progressType={PROGRESS_TYPE_VALUES.TIME}
              onClickItem={handleClickJobType}
            />
          </Box>

          <Box display="flex" justifyContent="center" width="100%">
            {jobDWOs.length === 0 ? (
              //TODO: When the Blank Timesheet logic is ready this has to be added back and change the <span> for a <button>.
              // <Button
              //   className={classes.button}
              //   disabled={isLoading}
              //   variant="outlined"
              //   onClick={handleClickOpenCreateDWOModal}
              // >
              //   Create Empty Timesheet
              // </Button>
              <span>No Timesheets</span>
            ) : (
              <Button
                className={classes.button}
                disabled={isLoading}
                variant="outlined"
                onClick={handleClickViewTimesheet}
              >
                View Timesheets
              </Button>
            )}
          </Box>

          {isCreateDWOModalOpen && (
            <GlobalModal
              modalMaxWidth="615px"
              onClickClose={handleClickCloseCreateDWOModal}
              onClickContinue={handleClickCreateDWOModal}
              open={isCreateDWOModalOpen}
              primaryLabel={"Create Timesheet"}
              showActionButtons={true}
              title="Create a Timesheet for this Job?"
            ></GlobalModal>
          )}

          {(isNoCrewMessage || isNoForemanMessage) && (
            <Modal
              className={classes.goBackModal}
              open={isCreateDWOModalOpen}
              onClose={handleClickCloseModals}
            >
              <Box className={classes.container}>
                <Box display="flex" justifyContent="center">
                  <Typography className={classes.header} component="h1">
                    {isNoCrewMessage
                      ? "Create a Crew before continue."
                      : "Assign a Foreman before continue."}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" padding="24px">
                  <Box width="150px">
                    <Button
                      className={classes.goBackButton}
                      fullWidth
                      variant="outlined"
                      onClick={handleClickCloseModals}
                    >
                      Go back
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}

          {isSelectCrewModalOpen && (
            <NoTimesheetsModal
              isOpen={isSelectCrewModalOpen}
              onClickClose={handleClickCloseTimesheetsModal}
            />
          )}
        </Fragment>
      )}
    </Paper>
  );
}
