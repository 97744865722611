import { makeStyles, createStyles } from "@material-ui/core";
import { DWOEmployeeModel, DWOModel } from "@dwo/shared/dist/models/DWOModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { addLocalTimeOffset, slashFormatDate } from "./dateUtils";
import { MainTableColumn, MainTableRow, rowSelectOptions } from "./tableUtils";
import { TextLink } from "components/TextLink";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import React from "react";
import { DropdownOption } from "components/Dropdown";
import { ALL_OPTION, DEFAULT_LIMIT } from "./sharedUtils";
import { ROLE_TYPES_LABELS, ROLE_TYPES_VALUES } from "./userManagementUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { DateRange } from "materialui-daterange-picker";
import { ToggleButtonOptions } from "components/SquareToggleButton";

export const crewHeadersStyles = makeStyles(() =>
  createStyles({
    standardHeader: {
      width: "16.6%",
    },
  }),
);

export interface CrewTimesheetManagement {
  date: Date | string;
  foreman?: EmployeeModel;
  supervisor?: EmployeeModel;
  region: string;
  status: string;
  crewDetailId: string;
}

export enum DwoStatusValues {
  DRAFT = "draft",
  SUBMITTED = "submitted",
  APPROVED = "approved",
}

export enum DwoStatusLabels {
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REAPPROVE = "Re-Approve",
}

export enum TimeSheetTabs {
  Crew,
  Employee,
}

export interface CrewFilters {
  searchValue: string;
  region: string[];
  status: string;
  sortingOptions: string[][];
  page: number;
  rowsPerPage: string;
  dateRange?: DateRange;
}

export interface EmployeeFilters {
  searchValue: string;
  role: string;
  status: number;
  sortingOptions: string[][];
  page: number;
  rowsPerPage: string;
  dateRange?: DateRange;
}

export const initialCrewFilters: CrewFilters = {
  searchValue: "",
  region: [],
  status: "",
  sortingOptions: [],
  page: 1,
  rowsPerPage: rowSelectOptions[0].value,
  dateRange: undefined,
};

export const initialEmployeeFilters: EmployeeFilters = {
  searchValue: "",
  role: "",
  status: ToggleButtonOptions.Left,
  sortingOptions: [],
  page: 1,
  rowsPerPage: rowSelectOptions[0].value,
  dateRange: undefined,
};

export const initialCrewQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  include: [{ foreman: ["Region", { employeeSupervisors: ["supervisor"] }] }],
};

export const initialEmployeeQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  where: {
    status: { $ne: DwoStatusValues.APPROVED },
  },
};

export const employeesDWOsListTableColumns: MainTableColumn[] = [
  { field: "employeeId", hasSorting: true, headerName: "# ID" },
  { field: "firstName", hasSorting: true, headerName: "NAME" },
  { field: "role", hasSorting: true, headerName: "ROLE" },
  { field: "supervisor", headerName: "SUPERVISOR" },
  { field: "foreman", headerName: "FOREMAN" },
  { field: "region", hasSorting: true, headerName: "REGION" },
  { field: "date", hasSorting: true, headerName: "SUBMIT DATE" },
];

export const timesheetsRoleDropdownOptions: DropdownOption[] = [
  ALL_OPTION,
  {
    label: ROLE_TYPES_LABELS.FOREMAN,
    value: ROLE_TYPES_VALUES.FOREMAN,
  },

  {
    label: ROLE_TYPES_LABELS.WORKER,
    value: ROLE_TYPES_VALUES.WORKER,
  },
];

export const getDwoStatusOptions = () => [
  {
    label: DwoStatusLabels.DRAFT,
    value: DwoStatusValues.DRAFT,
  },
  {
    label: DwoStatusLabels.SUBMITTED,
    value: DwoStatusValues.SUBMITTED,
  },
  {
    label: DwoStatusLabels.APPROVED,
    value: DwoStatusValues.APPROVED,
  },
];

export const getDWOEmployeeRows = (
  dwoEmployeeList: DWOEmployeeModel[],
  onClickEmployee: (dwoEmployee: DWOEmployeeModel) => void,
): MainTableRow[] =>
  dwoEmployeeList.map((dwoEmployeeElement: DWOEmployeeModel) => {
    const handleClickLink = () => onClickEmployee(dwoEmployeeElement);
    const foreman = {
      value: dwoEmployeeElement.dwoJob.dwo.foreman?.fullName || "",
    };
    const fullName = dwoEmployeeElement.employee.fullName;
    const id = { value: dwoEmployeeElement.employee.employeeId };
    const name = {
      value: (
        <TextLink
          text={fullName}
          url={`${ROOT_TIMESHEETS_MANAGEMENT}/employee/${dwoEmployeeElement.employee.id}`}
          onClickLink={handleClickLink}
        />
      ),
    };
    const region = {
      value: dwoEmployeeElement.dwoJob.job?.region?.name || "",
    };
    const role = {
      value:
        dwoEmployeeElement.employee.role.charAt(0).toUpperCase() +
        dwoEmployeeElement.employee.role.slice(1),
    };
    const submitDate = {
      value: slashFormatDate(addLocalTimeOffset(dwoEmployeeElement.date)),
    };
    const supervisor = {
      value: dwoEmployeeElement.dwoJob.job?.supervisor?.fullName || "",
    };

    const innerCells = [
      id,
      name,
      role,
      supervisor,
      foreman,
      region,
      submitDate,
    ];

    return {
      innerCells,
    };
  });

export const getTotalPages = (currentCount: number, currentLimit: number) => {
  const pages = Math.ceil(currentCount / currentLimit);

  if (pages >= 1) {
    return pages;
  }

  return 1;
};

export const mapToCrewTimesheetManagment = (
  dwo: DWOModel,
): CrewTimesheetManagement => {
  const date = addLocalTimeOffset(dwo.date as string);
  const foreman = dwo.foreman;
  const supervisors = dwo.foreman?.employeeSupervisors;
  //   ? dwo.foreman?.employeeSupervisors
  //       .filter((emSpv) => {
  //         return emSpv.supervisor.deletedAt === null;
  //       })
  //       .reverse()
  //   : [];
  // const supervisor = supervisors[0]?.supervisor;
  const supervisor =
    dwo.foreman?.employeeSupervisors && dwo.foreman.employeeSupervisors[0]
      ? dwo.foreman.employeeSupervisors[0].supervisor
      : undefined;
  let region = "";
  if (foreman && foreman.regions && foreman.regions.length > 0) {
    region = foreman.regions[0].name;
  }
  const status = dwo.status;
  const crewDetailId = dwo.id ? dwo.id.toString() : "";
  return { date, foreman, supervisor, region, status, crewDetailId };
};
